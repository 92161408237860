<template>
  <v-dialog
    v-model="dialog"
    :max-width="$vuetify.breakpoint.mobile ? '100%' : '30%'"
    @keydown.esc="close"
  >
    <v-card
      class="px-6 py-8"
      :loading="isLoading"
    >
      <v-toolbar
        color="white"
        dense
        flat
        class="align-center"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.chooseWorkspace') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          :icon-name="icons.mdiClose"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="close"
        />
      </v-toolbar>

      <v-card-text
        v-if="workspacesList.length > 0"
        class="pa-4"
      >
        <input-text
          v-model="search"
          left-icon="mdi-magnify"
          :placeholder="$t('deconve.search')"
        />
        <div
          class="mb-2 mt-6"
          style="max-height: 280px; overflow-y: auto"
        >
          <v-treeview
            :search="search"
            :items="workspacesList"
            item-text="name"
            activatable
            hoverable
            selected-color="primary"
            :active-class="
              selectedWorkspaceId.length > 0 ? 'v-treeview-node--active' : 'textBlack--text'
            "
            :active="selectedWorkspaceId"
            @update:active="onWorkspaceSelected"
          >
            <template v-slot:label="{ item }">
              <div class="d-flex align-center">
                <v-avatar
                  size="32"
                  color="primary"
                >
                  <v-img
                    v-if="item.profile_image"
                    :src="item.profile_image"
                    style="width: inherit; height: inherit;"
                    data-dd-privacy="hidden"
                  />
                  <span
                    v-else
                    style="color: white"
                  >{{ item.name[0] }}</span>
                </v-avatar>
                <span
                  class="d-sm-flex mr-2 neutralPrimary--text ml-2 subtitle-2"
                >
                  {{ item.name }}
                </span>
              </div>
            </template>
          </v-treeview>
        </div>
      </v-card-text>

      <v-card-text>
        <div
          v-if="selectedWorkspaceName"
          class="subtitle-2 text-sm-subtitle-1 font-weight-medium neutral--text"
        >
          {{ $t('deconve.selectedWorkspace') }}
        </div>
        <div
          v-if="selectedWorkspaceName"
          class="d-flex flex-wrap"
        >
          <v-chip
            close
            close-icon="mdi-close"
            label
            outlined
            @click:close="removeSelectedWorkspace"
          >
            {{ selectedWorkspaceName }}
          </v-chip>
        </div>
      </v-card-text>

      <v-card-actions
        class="pt-2"
      >
        <v-spacer />
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
          elevation="0"
          class="d-flex custom-transform-class text-none px-3"
          color="neutral"
          @click="close"
        >
          {{ $t('deconve.cancel') }}
        </v-btn>
        <v-btn
          :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
          elevation="0"
          class="d-flex custom-transform-class text-none px-3"
          color="primary"
          @click="confirm"
        >
          {{ $t('deconve.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="alert"
      color="warn"
    >
      {{ alertMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="alert=false"
        >
          <v-icon color="white">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mdiClose } from '@mdi/js';
import { mapActions, mapGetters } from 'vuex';
import SquareButton from './SquareButton.vue';
import InputText from './InputText.vue';

export default {
  name: 'WorkspacesDialog',
  components: {
    SquareButton,
    InputText,
  },
  data: () => ({
    dialog: false,
    selectedWorkspaceId: [],
    selectedWorkspace: null,
    search: '',
    isLoading: false,
    alert: false,
    alertMessage: '',
    icons: {
      mdiClose,
    },
    workspacesList: [],
  }),
  computed: {
    ...mapGetters({
      myWorkspace: 'workspace/myWorkspace',
      getWorkspacesSharedWithMe: 'workspace/getWorkspacesSharedWithMe',
    }),
    isOnDeconveHubWorkspace() {
      return this.$can('use', 'com.deconve.hub');
    },
    selectedWorkspaceName() {
      return this.selectedWorkspace?.name;
    },
  },
  methods: {
    ...mapActions({
      fetchWorkspace: 'workspace/fetchWorkspace',
    }),
    onWorkspaceSelected([workspaceId]) {
      if (workspaceId) {
        this.selectedWorkspaceId = [workspaceId];

        if (this.workspacesList) {
          this.selectedWorkspace = this.workspacesList.find(({ id }) => (id === workspaceId));
        } else {
          this.selectedWorkspace = null;
        }
      } else {
        this.selectedWorkspace = null;
      }
    },
    fetchWorkspaceList(workspaceId, scopes) {
      return new Promise(() => {
        this.isLoading = true;

        const workspaceIds = this.getWorkspacesSharedWithMe(scopes);

        const promises = workspaceIds.map((id) => this.fetchWorkspace(id));

        Promise.all(promises).then((workspaces) => {
          const sortedWorkspaces = [...workspaces];

          sortedWorkspaces.sort((a, b) => a.name.localeCompare(b.name));
          this.workspacesList = sortedWorkspaces;

          if (workspaceId) {
            this.onWorkspaceSelected([workspaceId]);
          }

          this.isLoading = false;
        }).catch(() => {
          this.isLoading = false;

          this.alertMessage = this.$t('deconve.couldNotFetchWorkspaces');
          this.alert = true;
        });
      });
    },
    open({ workspaceId, scopes }) {
      this.fetchWorkspaceList(workspaceId, scopes);
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    reset() {
      this.selectedWorkspace = null;
      this.selectedWorkspaceId = [];
    },
    close() {
      this.dialog = false;
      this.reset();
      return this.reject();
    },
    confirm() {
      this.resolve(this.selectedWorkspace);
      this.reset();
      this.dialog = false;
    },
    removeSelectedWorkspace() {
      this.selectedWorkspaceId.splice(0, 1);
    },
  },
};
</script>
