<template>
  <v-container
    class="ma-0 pa-0 d-flex"
    :class="`${rounded ? 'rounded': ''}`"
    :fill-height="fillHeight"
    fluid
    :style="getStyle"
  >
    <zoomable-image
      v-if="hasValidImages"
      :src="src"
      :has-zoom="hasZoom"
      @isZoomDestroyed="$emit('isZoomDestroyed')"
      @isZoomEnabled="$emit('isZoomEnabled')"
    >
      <template v-slot:default>
        <v-img
          eager
          aspect-ratio="1"
          :class="`${rounded ? 'transparentBackground rounded': 'transparentBackground'}`"
          height="100%"
          data-dd-privacy="hidden"
          :contain="contain"
          :src="src"
          :lazy-src="lazySrc"
        />
      </template>
    </zoomable-image>
    <v-row
      v-else
      no-gutters
      :class="`${rounded ? 'fill-height rounded': 'fill-height'}`"
      justify="center"
      align="center"
    >
      <div v-if="isLoadingImages">
        <v-progress-circular
          v-if="!hideLoadingStatus"
          size="16"
          indeterminate
          color="primary lighten-1"
        />
      </div>
      <v-col
        v-else
        class="ma-0 pa-0"
        align="center"
        justify="center"
      >
        <v-icon
          size="48px"
          color="neutral"
        >
          {{ personErrorNotificationIcon }}
        </v-icon>
        <div v-if="!$vuetify.breakpoint.mobile">
          <div class="text-body-5 neutral--text">
            {{ personErrorNotificationMessage }}
          </div>
          <v-btn
            v-if="hasReloadListener"
            text
            x-small
            color="primary"
            @click="$emit('reload')"
          >
            <span style="font-size: 12px">
              {{ $t('deconve.reload') }}
            </span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mdiImageBroken, mdiAccountAlert, mdiAccountCancel } from '@mdi/js';
import { PERSON_NOTIFICATION } from '@/utils/faceidNotifications';
import ZoomableImage from './ZoomableImage.vue';

export default {
  name: 'ImageContainer',
  components: {
    ZoomableImage,
  },
  inheritAttrs: false,
  props: {
    loading: { type: Boolean, default: true },
    hideLoadingStatus: { type: Boolean, default: false },
    personDisplayStatus: { type: String, default: PERSON_NOTIFICATION.isAInvalidPersonProfile },
    contain: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    height: { type: [String, Number], default: undefined },
    width: { type: [String, Number], default: undefined },
    fillHeight: { type: Boolean, default: false },
    src: { type: [String, Object], default: undefined },
    lazySrc: { type: [String, Object], default: '' },
    hasZoom: { type: Boolean, default: false },
  },
  data() {
    return {
      icons: {
        imageBroken: mdiImageBroken,
        accountAlert: mdiAccountAlert,
        accountCancel: mdiAccountCancel,
      },
    };
  },
  computed: {
    isNotToShowPersonProfile() {
      return this.personDisplayStatus === PERSON_NOTIFICATION.isNotToShowPersonProfile;
    },
    isAInvalidPersonProfile() {
      return this.personDisplayStatus === PERSON_NOTIFICATION.isAInvalidPersonProfile;
    },
    personErrorNotificationIcon() {
      if (this.isNotToShowPersonProfile) {
        return this.icons.accountAlert;
      }

      if (this.isAInvalidPersonProfile) {
        return this.icons.accountCancel;
      }

      return this.icons.imageBroken;
    },
    personErrorNotificationMessage() {
      if (this.isNotToShowPersonProfile) {
        return this.$t('deconve.collaborativeDatabaseProfile');
      }

      if (this.isAInvalidPersonProfile) {
        return this.$t('deconve.profileNotFound');
      }

      return this.$t('deconve.imageUnavailable');
    },
    hasReloadListener() {
      return this.$listeners?.reload;
    },
    hasValidImages() {
      return this.lazySrc?.length > 0 || this.src?.length > 0;
    },
    isLoadingImages() {
      return this.loading || this.lazySrc === undefined || this.src === undefined;
    },
    getStyle() {
      if (!this.fillHeight && this.width && this.height) {
        return { width: this.width, height: this.height };
      }

      return {};
    },
  },
};
</script>
