<template>
  <div
    style="position: relative; width: 100%; height: 100%;"
  >
    <span
      :style="{
        position: 'absolute',
        top: '0',
        left: '0',
        cursor: defineCursor,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        zIndex: hasZoom && !showZoom ? '1' : '0'}"
      @click="handleClick"
      @mousedown="handleMouseDown"
      @mousemove="handleMouseMove"
      @mouseup="handleMouseUp"
    />
    <slot
      v-if="!showZoom"
      name="default"
    />

    <div
      v-else
      class="zoom-box d-flex align-center"
      :style="{ cursor: defineCursor }"
      @click="handleClick"
      @mousedown="handleMouseDown"
      @mousemove="handleMouseMove"
      @mouseup="handleMouseUp"
    >
      <v-img
        :src="src"
        :style="zoomBoxStyle"
        eager
        data-dd-privacy="hidden"
        aspect-ratio="1"
        height="100%"
        contain
      />
    </div>
  </div>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

export default {
  name: 'ZoomableImage',
  props: {
    src: { type: [String, Object], default: undefined },
    zoomAmount: { type: Number, default: 2 },
    hasZoom: { type: Boolean, default: true },
  },
  data() {
    return {
      showZoom: false,
      isDragging: false,
      mouseDown: false,
      finalZoomAmount: this.zoomAmount,
      mouseX: 0,
      mouseY: 0,
      lastMouseX: 0,
      lastMouseY: 0,
      zoomLevel: 0,
      maxZoomLevel: 2,
      isClickActive: true,
    };
  },
  computed: {
    zoomBoxStyle() {
      return {
        transformOrigin: `${this.mouseX}px ${this.mouseY}px`,
        transform: `scale(${this.finalZoomAmount})`,
      };
    },
    defineCursor() {
      let response = 'zoom-in';

      if (this.showZoom) {
        response = this.isDragging ? 'grabbing' : 'grab';
      }

      return response;
    },
  },
  beforeDestroy() {
    this.$emit('isZoomDestroyed');
  },
  methods: {
    reset() {
      this.showZoom = false;
      this.mouseDown = false;
      this.zoomLevel = 0;
      this.finalZoomAmount = this.zoomAmount;
      this.$emit('isZoomEnabled');
    },
    configureZoom() {
      if (this.zoomLevel < this.maxZoomLevel) {
        this.showZoom = true;
        this.mouseDown = true;

        if (this.zoomLevel === 1) {
          this.finalZoomAmount *= 2;
        } else {
          this.$emit('isZoomEnabled');
        }

        this.zoomLevel += 1;
        return;
      }

      this.reset();
    },
    handleClick(event) {
      if (this.hasZoom && this.isClickActive) {
        this.configureZoom();

        if (this.showZoom) {
          this.mouseX = event.offsetX;
          this.mouseY = event.offsetY;
        }
      }
    },
    handleMouseDown() {
      this.isClickActive = true;

      if (this.mouseDown) {
        this.isDragging = true;
      }
    },
    handleMouseMove(event) {
      if (this.isDragging) {
        const deltaX = this.lastMouseX - event.clientX;
        const deltaY = this.lastMouseY - event.clientY;

        this.mouseX += deltaX;
        this.mouseY += deltaY;
        this.isClickActive = false;
      }

      this.lastMouseX = event.clientX;
      this.lastMouseY = event.clientY;
    },
    handleMouseUp() {
      this.isDragging = false;
    },
  },
};
</script>

<style scoped>
  .zoom-box {
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
  }
</style>
