<template>
  <v-card
    class="d-flex flex-column"
    outlined
    elevation="0"
    min-width="150"
    style="flex: 1; height: fit-content; width: 100%"
    :loading="!isOccurrenceImageLoaded"
    @click="seeOccurrence"
  >
    <div class="d-flex flex-column ma-4">
      <div
        class="d-flex justify-content-center align-items-center mx-0"
        style="overflow: hidden; border-radius: 4px; max-height: 250px"
        :style="{ minHeight: computedMinHeight }"
      >
        <image-container
          data-dd-privacy="hidden"
          width="50%"
          hide-loading-status
          :loading="!isOccurrenceImageLoaded"
          :src="occurrenceImage"
        />
      </div>
      <div
        class="d-flex flex-column mt-1"
        style="flex: 0"
      >
        <div class="d-flex flex-column">
          <div class="d-flex align-center justify-space-between">
            <v-tooltip
              bottom
              left
              open-delay="600"
              open-on-hover
            >
              <template v-slot:activator="{ on, attrs }">
                <strong
                  class="text-truncate subtitle-1 text-sm-h6 font-weight-bold textBlack--text"
                  data-dd-privacy="mask"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ occurrenceName }}
                </strong>
              </template>
              <span data-dd-privacy="mask">{{ occurrenceName }}</span>
            </v-tooltip>
          </div>

          <div class="d-flex align-end justify-space-between mt-2">
            <v-col
              class="d-flex flex-column"
            >
              <v-row
                style="line-break: anywhere; flex-wrap: nowrap"
                class="text-truncate caption text-sm-body-2 neutral--text"
              >
                <v-icon
                  class="mr-1"
                  color="neutral"
                  :size="$vuetify.breakpoint.mobile ? 16 : 20"
                >
                  {{ icons.notifications }}
                </v-icon>
                {{ occurrenceNumber }}
                {{ $tc(
                  'deconve.occurrenceNotification',
                  occurrenceNumber,
                  {n: occurrenceNumber}
                ) }}
              </v-row>
              <v-row
                style="line-break: anywhere; flex-wrap: nowrap"
                class="text-truncate caption text-sm-body-2 neutral--text mt-4"
              >
                <v-icon
                  class="mr-1"
                  color="neutral"
                  :size="$vuetify.breakpoint.mobile? 16: 20"
                >
                  {{ icons.calendar }}
                </v-icon>
                {{ createdAt }}
              </v-row>
            </v-col>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="occurrenceActivationStatusColor"
                  size="20"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ occurrenceActivationStatusIcon }}
                </v-icon>
              </template>
              <span>{{ occurrenceActivationStatusIconText }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import {
  mdiAccountBoxMultipleOutline, mdiCalendarRange, mdiNoteCheck, mdiNoteEdit,
} from '@mdi/js';

import ImageContainer from '@/components/ImageContainer.vue';

import { downloadImage } from '@/api';

export default {
  name: 'Occurrence',
  components: {
    ImageContainer,
  },
  props: {
    id: { type: String, required: true },
    data: { type: Object, required: true },
  },
  data() {
    return {
      isOccurrenceImageLoaded: false,
      occurrenceImage: undefined,
      icons: {
        notifications: mdiAccountBoxMultipleOutline,
        calendar: mdiCalendarRange,
        occurrenceFinishIcon: mdiNoteCheck,
        occurrenceNotFinishIcon: mdiNoteEdit,
      },
    };
  },
  computed: {
    ...mapGetters({
      getOccurrence: 'faceid/getOccurrence',
      getNotification: 'faceid/getNotification',
    }),
    occurrence() {
      return this.getOccurrence(this.id);
    },
    notification() {
      return this.getNotification(this.data.notification.id);
    },
    occurrenceName() {
      return this.occurrence?.name || this.notification?.video.name;
    },
    occurrenceNumber() {
      return this.occurrence?.notifications.length || 0;
    },
    createdAt() {
      if (this.occurrence) {
        return this.$moment(this.occurrence.created_at).format('DD/MM/YYYY HH:mm:ss');
      }

      return '';
    },
    occurrenceImageIsNull() {
      return this.occurrenceImage === '';
    },
    isOccurrenceFinished() {
      return this.occurrence?.is_finished;
    },
    isMobileVersion() {
      return this.$vuetify.breakpoint.mobile;
    },
    computedMinHeight() {
      if (this.occurrenceImage) {
        return 'auto';
      }

      return this.isMobileVersion ? '242px' : '282px';
    },
    occurrenceActivationStatusColor() {
      if (this.isOccurrenceFinished) {
        return 'positive';
      }

      return 'unreviewed';
    },
    occurrenceActivationStatusIcon() {
      if (this.isOccurrenceFinished) {
        return this.icons.occurrenceFinishIcon;
      }

      return this.icons.occurrenceNotFinishIcon;
    },
    occurrenceActivationStatusIconText() {
      if (this.isOccurrenceFinished) {
        return this.$t('deconve.occurrenceFinished');
      }

      return this.$t('deconve.occurrenceNotFinished');
    },
  },
  created() {
    this.fetchOccurrence(this.id).catch(() => {
      // nothing todo
    });
    this.fetchNotification();
  },
  methods: {
    ...mapActions({
      fetchNotificationData: 'faceid/fetchNotification',
      fetchOccurrence: 'faceid/fetchOccurrence',
    }),
    seeOccurrence() {
      this.$router.push({ name: 'faceid-occurrence', params: { occurrenceId: this.id } });
    },
    downloadOccurrencePreviewImage() {
      if (this.notification) {
        // The image database saves the best original image/face and sends them to the image
        // compressor. So, the notification can be loaded here before the image thumbnail to be
        // saved. Here we check if the thumbnail url is set or if the original face image url is
        // available.
        const { faces, best_face_index: bestFaceIndex } = this.notification;

        const { video_frame: faceImageInfo } = faces[bestFaceIndex];
        const { original_url: originalUrl, thumbnail_url: thumbnailUrl } = faceImageInfo;
        const bestFaceImageUrl = thumbnailUrl || originalUrl;

        downloadImage(bestFaceImageUrl).then((image) => {
          this.occurrenceImage = image;
          this.isOccurrenceImageLoaded = true;
        });
      } else {
        this.occurrenceImage = '';
        this.isOccurrenceImageLoaded = true;
      }
    },
    fetchNotification() {
      this.fetchNotificationData(this.data.notification.id).then(() => {
        this.downloadOccurrencePreviewImage();
      }).catch(() => {
        this.downloadOccurrencePreviewImage();
      });
    },
  },
};
</script>
